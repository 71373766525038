import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { MenuItem, PageLayout, Seo } from '../components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`
const OuterItemContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  justify-content: center;
`
const ItemContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
`

const FoodHeader = styled.h1`
  text-align: center;
`

interface FoodType {
  id: string
  name: string
}

interface Props {
  data: {
    menuItems: {
      nodes: {
        id: string
        foodType: FoodType
        name: string
        price?: number
        description?: string
        image: {
          asset: {
            altText: string
            gatsbyImageData: IGatsbyImageData
          }
        }
      }[]
    }
    foodTypes: {
      nodes: FoodType[]
    }
  }
}

const MenuPage: React.FC<Props> = ({ data }) => {
  const items = data.menuItems.nodes
  const groupedItems = data.foodTypes.nodes.map((type) => {
    return {
      name: type.name,
      group: items.filter((item) => item.foodType.name === type.name),
    }
  })

  return (
    <Seo title="Menu">
      <PageLayout>
        <Container>
          <OuterItemContainer>
            {groupedItems.map(({ name, group }) => {
              return (
                <>
                  <FoodHeader>{name}</FoodHeader>
                  <ItemContainer>
                    {group.map((item) => (
                      <MenuItem
                        key={item.id}
                        name={item.name}
                        description={item.description}
                        price={item.price}
                        imageUrl={item.image.asset.gatsbyImageData}
                      />
                    ))}
                  </ItemContainer>
                </>
              )
            })}
          </OuterItemContainer>
        </Container>
      </PageLayout>
    </Seo>
  )
}

export default MenuPage

export const menuQuery = graphql`
  query menu {
    menuItems: allSanityMenuItem(sort: { fields: [order], order: ASC }) {
      nodes {
        id
        foodType {
          name
        }
        name
        price
        description
        image {
          asset {
            altText
            gatsbyImageData(width: 200, height: 200)
          }
        }
        order
      }
    }
    foodTypes: allSanityFoodType {
      nodes {
        id
        name
      }
    }
  }
`
